import MobileNavigation from '/static/js/component/app-mobile-navigation.min.js';
import ImageTextColumnBlock from '/static/js/blocks/image-text-column-block.min.js';
import ImageTextRowBlock from '/static/js/blocks/image-text-row-block.min.js';
import EvSurveyBlock from '/static/js/blocks/ev-survey-block.min.js';

new MobileNavigation('.app', {})

// BACKLOG: (rodneyc) - Deepbraco has a solution to include the script only on the blocks where it is needed and de-dupe multiple usages.
// https://bitbucket.org/deeptech/deepnet-deepbracore/src/develop/docs/10.%20BlockList%20Editors.md
// This means this script is included on all pages rather than just where it is needed.
new ImageTextColumnBlock('.app', {})
new ImageTextRowBlock('.app', {})
new EvSurveyBlock('.app', {})